import { GetMyPackageParametersType } from "services/hermes/packages";
import { IMyPackagesStoreType } from "./myPackages.reducer";

export const CLEAR_MY_PACKAGES = "CLEAR_MY_PACKAGES";
export const GET_MY_PACKAGES = "GET_MY_PACKAGES";
export const GET_MY_PACKAGES_SUCCESS = "GET_MY_PACKAGES_SUCCESS";

export type IGetMyPackagesSuccessActionType = {
  type: typeof GET_MY_PACKAGES_SUCCESS;
  payload: IMyPackagesStoreType;
} & Pick<IGetMyPackagesActionType, "config">;

export interface IClearMyPackagesActionType {
  type: typeof CLEAR_MY_PACKAGES;
}

export interface IGetMyPackagesActionType {
  type: string;
  payload: GetMyPackageParametersType;
  config?: {
    getDeliveryReceipts?: boolean;
    getLabelImages?: boolean;
    amendExisting?: boolean;
  };
}

function getMyPackages(
  payload: IGetMyPackagesActionType["payload"],
  config?: IGetMyPackagesActionType["config"],
): IGetMyPackagesActionType {
  return { type: GET_MY_PACKAGES, payload, config };
}

function getMyPackagesSuccess(
  packageResponse: IMyPackagesStoreType,
  config?: IGetMyPackagesSuccessActionType["config"],
): IGetMyPackagesSuccessActionType {
  return {
    type: GET_MY_PACKAGES_SUCCESS,
    payload: packageResponse,
    config,
  };
}

function clearMyPackages(): IClearMyPackagesActionType {
  return { type: CLEAR_MY_PACKAGES };
}

export const myPackagesActions = {
  clearMyPackages,
  getMyPackages,
  getMyPackagesSuccess,
};
