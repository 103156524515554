import { generateQueryString } from "helpers/generateQueryString.helper";
import { IAuthenticatedRequestDefinition } from "types/IAuthenticatedRequestDefinition";
import { IPaginatedTableSearchRequestType } from "types/IPaginatedTableSearchRequestType";
import { CancelScheduledDeliveriesActionType } from "slices/cancelledScheduledDeliveryOutcomes/cancelledScheduledDeliveryOutcomes.actions";
import { IGetPackageByIdScheduleHistoryPayloadType } from "slices/scheduleHistory/scheduleHistory.actions";
import { IPostDeliverPackagePayloadType } from "slices/package/package.actions";
import { RECEIVED } from "constants/generalSettings/packageStatuses";
import { IPackageStoreType } from "slices/package/package.reducer";
import { CSATRating } from "slices/pendingRating/pendingRating.actions";

function getScheduledDelivery({
  fetchPackageId,
}: {
  fetchPackageId: number;
}): IAuthenticatedRequestDefinition {
  return {
    requestOptions: {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: `/fetchPackages/${fetchPackageId}/schedule-delivery`,
  };
}

function postCancelScheduleDelivery(requestBody: {
  fetchPackageId: number;
}): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/fetchPackages/cancel-schedule-delivery",
  };
}

function postScheduledDelivery(
  requestBody: IPostScheduledDeliveryPayloadType,
): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/fetchPackages/schedule-delivery",
  };
}

export interface IPostScheduledDeliveryPayloadType {
  fetchPackageId: number;
  deliveryWindowId: number;
  deliveryDate: string;
  deliveryPreferenceOverride?: "LEAVE" | "DO_NOT_LEAVE";
}

const getPendingRating = (): IAuthenticatedRequestDefinition => ({
  requestOptions: {
    method: "GET",
  },
  requestUrl: "/fetchPackages/my/pendingRating",
});

const postRating = (rating: CSATRating): IAuthenticatedRequestDefinition => ({
  requestOptions: {
    method: "POST",
  },
  body: rating,
  requestUrl: "/fetchPackages/my/rating",
});

function getMyPackages({
  pageSize,
  page,
  timeScope,
}: GetMyPackageParametersType): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/fetchPackages/my?pageSize=${pageSize}&page=${page}${
      timeScope ? `&timeScope=${timeScope}` : ""
    }`,
  };
}

export type GetMyPackageParametersType = {
  pageSize: number;
  page: number;
  timeScope?: "today" | "historical";
};

function getPackage(packageId: string): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/fetchPackages/" + packageId,
  };
}

const getPackageByIdScheduleHistory = ({
  fetchPackageId,
}: IGetPackageByIdScheduleHistoryPayloadType): IAuthenticatedRequestDefinition => ({
  requestOptions: {
    method: "GET",
  },
  requestUrl: `/fetchPackages/${fetchPackageId}/scheduleHistory`,
});

function getPackageRelocationAuditTrail(
  packageId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/fetchPackages/" + packageId + "/relocation-audit",
  };
}

function postDeliverPackage(
  requestBody: IPostDeliverPackagePayloadType,
): IAuthenticatedRequestDefinition {
  return {
    body: requestBody,
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: "/fetchPackages/deliver-package",
  };
}

const postExceptionPackages = (
  fetchPackageIds: number[],
): IAuthenticatedRequestDefinition => ({
  body: {
    fetchPackageIds,
    timestamp: new Date().toISOString(),
  },
  requestOptions: {
    method: "POST",
  },
  requestUrl: "/fetchPackages/exception-packages",
});

function postPickupRequested({
  fetchPackageId,
}: {
  fetchPackageId: number;
}): IAuthenticatedRequestDefinition {
  return {
    requestOptions: {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
    requestUrl: `/fetchPackages/${fetchPackageId}/pickup-requested`,
  };
}

function reprintPackageLabel(
  packageId: string,
): IAuthenticatedRequestDefinition {
  return {
    body: {
      fetchPackageId: packageId,
    },
    requestOptions: { method: "POST" },
    requestUrl: "/fetchPackages/reprint-label",
  };
}

function savePackage(
  parameters: IPackageStoreType | Partial<IPackageStoreType>,
): IAuthenticatedRequestDefinition {
  // If this is a new package, set status to received
  if (
    !parameters.hasOwnProperty("fetchPackageId") ||
    parameters.fetchPackageId === null
  ) {
    parameters.status = RECEIVED;
  }

  return {
    body: parameters,
    requestOptions: { method: "POST" },
    requestUrl: "/fetchPackages",
  };
}

function getPackageDeliveryReceipt({
  fetchPackageId,
}: {
  fetchPackageId: string;
}): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: `/fetchPackages/${fetchPackageId}/delivery-receipt`,
  };
}

const cancelScheduledDeliveries = (
  requestBody: CancelScheduledDeliveriesActionType["payload"],
): IAuthenticatedRequestDefinition => ({
  body: requestBody,
  requestOptions: {
    method: "POST",
  },
  requestUrl: "/fetchPackages/cancel-schedule-delivery/multiple",
});

const searchPackages = (
  parameters: IPaginatedTableSearchRequestType,
): IAuthenticatedRequestDefinition => ({
  requestOptions: { method: "GET" },
  requestUrl: "/fetchPackages/search" + generateQueryString(parameters),
});

const getMyPackage = (
  fetchPackageId: number,
): IAuthenticatedRequestDefinition => ({
  requestOptions: {
    method: "GET",
  },
  requestUrl: `/fetchPackages/my/${fetchPackageId}`,
});

function getPackageAuditTrail(
  packageId: string,
): IAuthenticatedRequestDefinition {
  return {
    requestOptions: { method: "GET" },
    requestUrl: "/fetchPackages/" + packageId + "/audit",
  };
}

function getPackageDeliveryWindowOptions({
  fetchPackageId,
}: {
  fetchPackageId: number;
}): IAuthenticatedRequestDefinition {
  return {
    requestOptions: {
      headers: { "Content-Type": "application/json", "X-API-VERSION": "2" },
      method: "GET",
    },
    requestUrl: `/fetchPackages/${fetchPackageId}/delivery-times`,
  };
}

export const packagesService = {
  getScheduledDelivery,
  postCancelScheduleDelivery,
  postScheduledDelivery,
  getMyPackages,
  getPackage,
  getPackageRelocationAuditTrail,
  postDeliverPackage,
  postPickupRequested,
  reprintPackageLabel,
  savePackage,
  getPackageByIdScheduleHistory,
  postExceptionPackages,
  getPackageDeliveryWindowOptions,
  getMyPackage,
  searchPackages,
  cancelScheduledDeliveries,
  getPackageDeliveryReceipt,
  getPackageAuditTrail,
  getPendingRating,
  postRating,
};
