import { defaultState } from "default-state";
import { IMyPackageStoreType } from "slices/myPackage/myPackage.reducers";
import {
  CLEAR_MY_PACKAGES,
  GET_MY_PACKAGES_SUCCESS,
  IClearMyPackagesActionType,
  IGetMyPackagesSuccessActionType,
} from "./myPackages.actions";

export interface IMyPackagesStoreType {
  content: Array<IMyPackageStoreType>;
  pageable: {
    sort: {
      unsorted: boolean;
      sorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  size: number;
  number: number;
  empty: false;
}

// type definitions
type MyPackagesActionsTypes =
  | IGetMyPackagesSuccessActionType
  | IClearMyPackagesActionType;

export function myPackages(
  state = defaultState.myPackages,
  action: MyPackagesActionsTypes,
): Partial<IMyPackagesStoreType> {
  switch (action.type) {
    case CLEAR_MY_PACKAGES:
      return defaultState.myPackages;
    case GET_MY_PACKAGES_SUCCESS:
      if (action.config?.amendExisting) {
        return {
          ...action.payload,
          content: !!state.content
            ? state.content.concat(action.payload.content)
            : action.payload.content,
        };
      } else {
        return action.payload;
      }
    default:
      return state;
  }
}
