import { IAuthType } from "slices/authentication/authentication.actions";
import { IAuthenticationStateType } from "slices/authentication/authentication.reducer";
import { CurrentUserState } from "slices/currentUser/currentUser.reducer";
import { myPackageLabelsByFetchPackageId } from "slices/myPackageLabelsByFetchPackageId";
import { SearchHermesStore } from "slices/searchHermes/searchHermes.reducers";
import { localStorageGetItemSafe } from "helpers/localStorage/localStorageGetItemSafe";
import { CSATData } from "slices/csatData";
import { defaultState as wfmDeliveryWindowSchedule } from "slices/wfmDeliveryWindowSchedule/reducer";
import { initialState as userRoleMembership } from "slices/userRoles/userRoleMembershipState";
import { initialState as navigateAway } from "slices/navigateAway/navigateAwayState";
import { IRootStateType } from "types/IRootStateType";
const searchHermesStore: SearchHermesStore = {
  batches: {},
  buildings: {},
  packages: {},
  users: {},
  warehouseLocations: {},
  warehouses: {},
};

// Default loggedIn to false
let loggedIn = false;

// Default tokens to empty object
let tokens: Partial<IAuthType> = {};

const localAuthData = localStorageGetItemSafe("auth");
if (!!localAuthData) {
  tokens = localAuthData;
  if ("accessToken" in localAuthData) {
    loggedIn = true;
  }
}

export const defaultState = {
  applicationSettings: {},
  authentication: {
    loggedIn,
    tokens,
    loginStatus: "",
  } as IAuthenticationStateType,
  automatedRoutes: {
    routes: [],
  },
  availableDeliveryWindows: null,
  batch: {},
  batches: {},
  building: {},
  buildings: {},
  buildingsByMarket: null,
  cancelledScheduledDeliveryOutcomes: null,
  coupon: null,
  csatData: CSATData.defaultState,
  wfmDeliveryWindowSchedule,
  currentUser: null as CurrentUserState,
  deliveryWindows: null,
  errorMessages: {},
  labelImage: null,
  loading: {},
  loggedPackages: [],
  loggingAnalysisJob: {},
  markets: null,
  metaSchema: undefined,
  myDriverBatches: [],
  myPackage: null,
  myPackageLabelsByFetchPackageId: myPackageLabelsByFetchPackageId.defaultState,
  myPackages: {} as Partial<IRootStateType["myPackages"]>,
  navigateAway,
  package: {},
  packageAuditTrail: { content: [] },
  packageDeliveryReceipts: {},
  packageDeliveryTimes: {},
  packageDeliveryWindowOptions: [],
  packageWarehouseLocationHistory: null,
  packages: {},
  pendingRating: null,
  prices: [],
  residentialUser: {},
  residentialUserAuditHistory: null,
  scheduledDelivery: null,
  scheduleHistory: [],
  searchHermes: searchHermesStore,
  subscriptions: [],
  subscriptionIntent: null,
  ui: {
    modals: {},
    notifications: [],
  },
  user: {},
  userAuditHistory: null,
  driverUserAuditHistory: [],
  users: {},
  warehouse: {},
  warehouseFilter: false,
  warehouseLocation: {},
  warehouseLocations: {},
  warehousePrinters: {},
  warehouseUser: {},
  warehouses: {},
  userRoleMembership,
};
