import { networkActions } from "slices/authentication/network.actions";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import {
  GET_MY_PACKAGES,
  GET_MY_PACKAGES_SUCCESS,
  IGetMyPackagesActionType,
  IGetMyPackagesSuccessActionType,
  myPackagesActions,
} from "./myPackages.actions";

import { IMyPackagesStoreType } from "./myPackages.reducer";
import { packagesService } from "services/hermes/packages";
import { PackageStatusOptions } from "types/generalSettingsEnums";
import { packageDeliveryReceiptsActions } from "slices/packageDeliveryReceipts/packageDeliveryReceipts.actions";
import { labelImageActions } from "slices/labelImage/labelImage.actions";

function* getMyPackagesWorker(action: IGetMyPackagesActionType) {
  yield put(myPackagesActions.clearMyPackages());
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const requestOptions: ReturnType<typeof packagesService.getMyPackages> =
      yield call(packagesService.getMyPackages, action.payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: IMyPackagesStoreType = yield call(
      networkActions.makeAuthenticatedRequest,
      requestOptions,
      true,
    );
    yield put(myPackagesActions.getMyPackagesSuccess(response, action.config));
  } catch (error) {}
}

function* getMyPackagesSuccessWorker(action: IGetMyPackagesSuccessActionType) {
  if (!!action.config?.getLabelImages) {
    for (const fetchPackage of action.payload.content) {
      yield put(
        labelImageActions.getLabelImage(`${fetchPackage.fetchPackageId}`),
      );
    }
  }

  if (!!action.config?.getDeliveryReceipts) {
    for (const fetchPackage of action.payload.content) {
      if (fetchPackage.status === PackageStatusOptions.DELIVERED) {
        yield put(
          packageDeliveryReceiptsActions.getPackageDeliveryReceipt({
            fetchPackageId: `${fetchPackage.fetchPackageId}`,
          }),
        );
      }
    }
  }
}
export const myPackagesSagas = function* (): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(GET_MY_PACKAGES, getMyPackagesWorker);
  yield takeLatest(GET_MY_PACKAGES_SUCCESS, getMyPackagesSuccessWorker);
};
